/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
/*<div style={{float: 'right', shapeOutside:'inset(0 0 0 0)', display:'inline-block'}} className="w-full md:w-2/5 p-6 bg-gray-700 rounded-l-lg text-white inverse">*/
/*<div style={{float: 'right', shapeOutside:'inset(0 0 0 0)', display:'inline-block'}} className="w-full md:w-2/5 p-6 bg-gray-700 rounded-l-lg text-white inverse">*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {faPhoneSquareAlt} from '@fortawesome/free-solid-svg-icons';
import {faExternalLinkAlt} from "@fortawesome/pro-regular-svg-icons";
import {faBrowser} from "@fortawesome/pro-solid-svg-icons";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h2: "h2",
    p: "p",
    h4: "h4",
    a: "a",
    h3: "h3"
  }, _provideComponents(), props.components), {FontAwesomeIcon} = _components;
  if (!FontAwesomeIcon) _missingMdxReference("FontAwesomeIcon", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Health Care"), "\n", React.createElement(_components.h2, null, "Burton Surgery"), "\n", React.createElement("div", {
    className: "flex flex-wrap"
  }, React.createElement("div", {
    className: "w-full md:w-3/5 pr-6"
  }, React.createElement(_components.p, null, "The Surgery, Norfolk Avenue, Burton-upon-Stather, DN15 9EW"), React.createElement(_components.h4, null, React.createElement(_components.a, {
    href: "tel:+441724720202"
  }, React.createElement(FontAwesomeIcon, {
    icon: faPhoneSquareAlt
  }), " : 01724 720202")), React.createElement("p", null, "For more details and opening times please visit the ", React.createElement("a", {
    title: "Winterton Medical Practice",
    href: "http://www.wintertonmedicalpractice.nhs.uk/index.html",
    target: "_blank",
    rel: "noopener"
  }, React.createElement(FontAwesomeIcon, {
    size: "xs",
    icon: faBrowser
  }), " : Winterton Medical Practice website ", React.createElement(FontAwesomeIcon, {
    size: "xs",
    icon: faExternalLinkAlt
  }), " "))), React.createElement("div", {
    className: "w-full md:w-2/5 md:pl-6 mb-4 md:mb-0 text-right p-4 z-40 md:ml-6 bg-gray-700 rounded-r-lg md:rounded-r-none rounded-l-lg text-white inverse float-right inline-block inset-0"
  }, React.createElement("div", {
    className: "mapouter"
  }, React.createElement("div", {
    className: "gmap_canvas"
  }, React.createElement("iframe", {
    width: "300",
    height: "300",
    id: "gmap_canvas",
    src: "https://maps.google.com/maps?q=The%20Surgery%2C%20Norfolk%20Avenue%2C%20Burton-upon-Stather%2C%20DN15%209EW&t=&z=17&ie=UTF8&iwloc=&output=embed",
    frameBorder: "0",
    scrolling: "no",
    marginHeight: "0",
    marginWidth: "0"
  }))))), "\n", React.createElement(_components.h2, null, "Winterton Medical Practice"), "\n", React.createElement("div", {
    className: "flex flex-wrap"
  }, React.createElement("div", {
    className: "w-full md:w-3/5 pr-6"
  }, React.createElement(_components.p, null, "Winterton Medical Practice, Manlake Avenue, Winterton, Scunthorpe DN15 9TA"), React.createElement(_components.h4, null, React.createElement(_components.a, {
    href: "tel:+441724732202"
  }, React.createElement(FontAwesomeIcon, {
    icon: faPhoneSquareAlt
  }), " : 01724 732202")), React.createElement(_components.h4, null, React.createElement("a", {
    title: "Winterton Medical Practice",
    href: "http://www.wintertonmedicalpractice.nhs.uk/index.html",
    target: "_blank",
    rel: "noopener"
  }, React.createElement(FontAwesomeIcon, {
    icon: faBrowser,
    size: "sm"
  }), " : Winterton Medical Practice website ", React.createElement(FontAwesomeIcon, {
    size: "xs",
    icon: faExternalLinkAlt
  }))), React.createElement(_components.p, null, "For more details and opening times please visit the Winterton Medical Practice website"), React.createElement(_components.p, null, "The practice has launched its own Facebook page, which will be regularly updated to keep patients informed.")), React.createElement("div", {
    className: "w-full md:w-2/5 md:pl-6 mb-4 md:mb-0 text-right p-4 z-40 md:ml-6 bg-gray-700 rounded-r-lg md:rounded-r-none rounded-l-lg text-white inverse float-right inline-block inset-0"
  }, React.createElement("div", {
    className: "mapouter"
  }, React.createElement("div", {
    className: "gmap_canvas"
  }, React.createElement("iframe", {
    width: "300",
    height: "300",
    id: "gmap_canvas",
    src: "https://maps.google.com/maps?q=The%20Surgery%2C%20Manlake%20Avenue%2C%20Winterton%2C%20Scunthorpe%20DN15%209TA&t=&z=17&ie=UTF8&iwloc=&output=embed",
    frameBorder: "0",
    scrolling: "no",
    marginHeight: "0",
    marginWidth: "0"
  }))))), "\n", React.createElement(_components.h3, null, "North Lincolnshire NHS Minor Ailment Scheme"), "\n", React.createElement(_components.p, null, "North Lincolnshire NHS has adopted a Minor Ailment Scheme working with General Practitioners. Although this is not directly connected with the Winterton Medical Practice, at a recent meeting of the Winterton Medical Practice Patient Participation Group, concern was expressed that few patients knew of this scheme."), "\n", React.createElement(_components.p, null, "Anyone registered with a North Lincolnshire GP surgery and who has a current exemption from payment, can register with a North Lincolnshire Pharmacy for advice and treatment on numerous symptoms ranging from ear wax to nasal congestion."), "\n", React.createElement(_components.p, null, "Further information, in leaflet form, is available from local pharmacies and Winterton and Burton surgeries."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
